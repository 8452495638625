import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { slide as Menu } from 'react-burger-menu'
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import { Link } from 'react-router-dom';
import logo from "../../images/ranchLogo.png";
import headerBackground from "../../images/headerBackground.png";
import { useTranslation } from 'react-i18next';
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { Container } from "components/misc/Layouts.js";
import HamburgerMenu from "./HamburgerMenu.js";


const Spacer = tw.div`w-3`;
const HamburgerContainer = tw.div`  pt-10`;
// const SpacerContainer = tw.div`w-11/12`;
const Header =
styled.header(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`flex justify-between items-center
  w-full mx-auto
  h-56 3xs:h-20 xxs:h-20 xs:h-20 sm:h-20 md:h-20 lg:h-50pp xl:h-50pp 2xl:h-50pp
 
  px-56 3xs:px-2 xxs:px-2 xs:px-2 sm:px-5 md:px-5 lg:px-5 xl:px-10 2xl:px-32
  pt-0 
  bg-cover bg-no-repeat bg-center `
]);


export const NavLinks = tw.div`inline-block`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500

`;

const BookNowButtonDesktopContainer = tw.div`
w-full grid grid-cols-3 h-32 justify-end`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-white/25 text-gray-100
  text-xl  xxs:text-base xs:text-base lg:text-base xl:text-xl 2xl:text-xl md:text-xl sm:text-base
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;
const ThreeColumnContainerBookNow = styled.div`
  ${tw`mt-1 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center  mx-auto `}
`;
export const LogoLink = styled.a`
  ${tw`
`};
  img {
    ${tw`
    items-center justify-center
    h-32 3xs:h-32 xxs:h-32 xs:h-24 sm:h-32 md:h-32 lg:h-40 xl:h-40 2xl:h-50pp 
    px-5
    pt-0  3xs:pt-10 xxs:pt-10 xs:pt-10 sm:pt-10 md:pt-10 lg:pt-10 xl:pt-10 2xl:pt-10 
    pb-10 xs:pb-0 sm:pb-5 md:pb-8 lg:pb-10
    `}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex justify-between w-full`
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-header-color`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */
  const { t, i18n } = useTranslation();
  const defaultLinks = [
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <Header imageSrc={headerBackground} className={className || "header-light"}> 
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {links}
      </DesktopNavLinks>
      
    <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
    <BookNowButtonDesktopContainer>
    {logoLink}
   
    <HamburgerContainer>
      <HamburgerMenu />
   
    
    </HamburgerContainer>
    </BookNowButtonDesktopContainer>
   
    
   
    
   
    
      
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
};
